

import styled from 'styled-components'

export const ItemContainer = styled.div`
    iframe {
        margin: 0 !important;
    }

    .personalizedReport {
        height: 100vh;
        width: 100%;
        position: relative;
    }
`