import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'

import * as Yup from 'yup'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import CardBody from 'components/CardBody'
import BtnRed from 'components/Button/BtnRed'
import BtnBox from 'components/Button/BtnBox'
import BtnWhite from 'components/Button/BtnWhite'
import BtnOrange from 'components/Button/BtnOrange'
import Modal from 'components/Modal'

import api from 'services/api/api'

import { handleCards } from 'services/api/reportCards'

import { setErrors } from 'utils'

const validation = yup => ({
    name: yup.string()
        .required('Campo obrigatório')
})

const NewReportCollection = () => {
    const [carousel, setCarousel] = useState({})
    const [cards, setCards] = useState([])

    const [visibleModal, setVisibleModal] = useState(false)

    const [messages, setMessages] = useState({ messages: [], alert: '' })

    const history = useHistory()
    const params = useParams()

    const formik = useFormik({
      initialValues: { 
        name: carousel.name
      },
      enableReinitialize: true,
      validationSchema: Yup.object(validation(Yup)),
      onSubmit: async () => {
        try {
          await api.put(`/carousels/${params.id}`, {
            ...formik.values
          })

          history.push('/carrosseis')
        } catch (error) {
          setMessages({ messages: setErrors(error), alert: 'alert-orange' })
        }
      }
    })

    useEffect(() => {
      (async () => {
        const cardsData = await handleCards()

        setCards(cardsData)
      })()
    }, [])

    useEffect(() => {
      (async () => {
        try {
          const { data } = await api.get(`/carousels/${params.id}`)
          setCarousel(data)
        } catch(error) {}
      })()
    }, [params.id])

    const handleDelete = async () => {
      try {
        await api.delete(`/carousels/${params.id}`)

        return history.push("/carrosseis");
      } catch(error) {
        setMessages({ messages: setErrors(error), alert: 'alert-orange' })
      }
    }

    return (
        <>
        <TitleBar label="Carrosséis" currentPage="" />

        <Content>
          <Card>
            <CardTitle title="Editar">
              <BtnOrange onClick={() => setVisibleModal(true)}>Excluir</BtnOrange>
            </CardTitle>

            <Content>
              <CardBody>
                <form onSubmit={formik.handleSubmit}>
                  <Messages formMessages={messages.messages} alert={messages.alert} />

                  <Input name="name" label="Nome" formik={formik} />

                  <BtnBox>
                    <BtnBlue type="submit" disabled={formik.isSubmitting}>
                      {formik.isSubmitting ? <Spinner /> : "Enviar"}
                    </BtnBlue>
                  </BtnBox>
                </form>
              </CardBody>
            </Content>
          </Card>
        </Content>

        <Modal visibleModal={visibleModal} setVisibleModal={setVisibleModal}>
          <Card>
            <Content>
              <Content>
                <CardBody>
                  <p>Deseja excluir este carrossel de relatórios?</p>

                  <BtnBox>
                    <BtnRed onClick={handleDelete}>Excluir</BtnRed>
                    <BtnWhite onClick={() => setVisibleModal(false)}>Cancelar</BtnWhite>
                  </BtnBox>
                </CardBody>
              </Content>
            </Content>
          </Card>
        </Modal>
      </>
    )
}

export default NewReportCollection