import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { IoMdAnalytics } from 'react-icons/io'
import { BiSlideshow } from 'react-icons/bi'

import { 
  FaUsers, 
  FaIndustry, 
  FaChartArea, 
  FaImages, 
  FaLayerGroup } from 'react-icons/fa'

import { Context } from 'contexts/context'

import MenuItem from 'components/MenuItem'

import { Sidebar, Menu, SidebarBrand, User, Name, Email } from './styled'

const Component = () => {

  const { toggle, setToggle, user } = useContext(Context)

  return (
    <Sidebar
      className={`
        ${toggle ? 'open' : 'closed'}
        ${toggle ? 'menu-open' : 'menu-closed'}
      `}
    >
      <div>
        <Link to="/dashboard">
          <SidebarBrand className={toggle ? 'open' : 'closed'} >
            <img className="logo" src="/logo-big.png" alt="unicom" />
          </SidebarBrand>
        </Link>

        <User to="/profile">
          <img key={String(user.file_url)} className={`${toggle ? 'open' : 'closed'}`} src={user.file_url ? `${process.env.REACT_APP_URL_API}/${user.file_url}` : '/user.svg'} alt="analyticdbi" />
          
          <div className={`${toggle ? 'open' : 'closed'}`}>
            <Email>Bem vindo</Email>
            <Name>{user.name}</Name>
          </div>
        </User>

        <Menu>
          {user.roles === 'admin' ? <MenuItem onClick={() => setToggle(false)} path="/grupos" icon={<FaIndustry />} label="Relatórios" /> : <MenuItem path="/relatorios" icon={<FaChartArea />} label="Relatórios" />}
          {user.roles === 'admin' && <MenuItem onClick={() => setToggle(false)} path="/usuarios" icon={<FaUsers />} label="Usuários" />}
          {user.roles === 'admin' && <MenuItem onClick={() => setToggle(false)} path="/cards" icon={<FaImages />} label="Cards" />}
          {user.roles === 'admin' && <MenuItem onClick={() => setToggle(false)} path="/colecoes" icon={<FaLayerGroup />} label="Grupos" />}
          {user.roles === 'admin' && <MenuItem onClick={() => setToggle(false)} path="/analytics" icon={<IoMdAnalytics />} label="Analytics" />}
          {user.roles === 'admin' && <MenuItem onClick={() => setToggle(false)} path="/carrosseis" icon={<BiSlideshow />} label="Carrossel" />}
        </Menu>
      </div>
    </Sidebar>
  )
}

export default Component
