import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'

import * as Yup from 'yup'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import Select from 'components/Form/LabelSelect'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import CardBody from 'components/CardBody'
import Table from 'components/Table'
import BtnBox from 'components/Button/BtnBox'

import api from 'services/api/api'

import { handleCards } from 'services/api/reportCards'

import { setErrors } from 'utils'

const validation = yup => ({
  report_id: yup.string().required('Campo obrigatório'),
  time: yup.string().required('Campo obrigatório')
})

const NewReportCollection = () => {
    const [carousel, setCarousel] = useState({})
    const [cards, setCards] = useState([])

    const [messages, setMessages] = useState({ messages: [], alert: '' })

    const history = useHistory()
    const params = useParams()

    const formik = useFormik({
      initialValues: { 
        time: "",
        report_id: "",
        duration: 0
      },
      enableReinitialize: true,
      validationSchema: Yup.object(validation(Yup)),
      onSubmit: async () => {
        const { report_id, duration, time } = formik.values

        try {
          await api.post(`/carousels/${params.id}/reports`, {
            report_id,
            time: time === "Segundos" ? duration * 1000 : duration * 1000 * 60
          })

          history.push(`/carrosseis/${params.id}/relatorios`)
        } catch (error) {
          setMessages({ messages: setErrors(error), alert: 'alert-orange' })
        }
      }
    })

    useEffect(() => {
      (async () => {
        const cardsData = await handleCards()

        setCards(cardsData)
      })()
    }, [])

    useEffect(() => {
      (async () => {
        try {
          const { data } = await api.get(`/carousels/${params.id}`)
          setCarousel(data)
        } catch(error) {}
      })()
    }, [params.id])

    return (
        <>
        <TitleBar label="Carrosséis" currentPage="" />

        <Content>
          <Card>
            <CardTitle title='Carrossel'></CardTitle>

            <Table>
              <thead>
              <tr>
                  <th>Nome</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                  <td>{carousel.name}</td>
              </tr>
              </tbody>
            </Table>
          </Card>

          <Card>
            <Content>
              <CardTitle title="Cadastrar" />

              <CardBody>
                <form onSubmit={formik.handleSubmit}>
                  <Messages formMessages={messages.messages} alert={messages.alert} />

                  <Select name="report_id" label="Relatórios" formik={formik}>
                    <option value="">Selecione...</option>

                    {cards.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>

                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: '88%' }}>
                      <Select name="time" label="Tempo" formik={formik}>
                        <option value="">Selecione...</option>

                        {['Segundos', 'Minutos'].map(item => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Select>
                    </div>

                    <div style={{ width: '10%' }}>
                      <Input name="duration" type="number" label="Duração" formik={formik} />
                    </div>
                  </div>

                  <BtnBox>
                    <BtnBlue type="submit" disabled={formik.isSubmitting}>
                      {formik.isSubmitting ? <Spinner /> : "Enviar"}
                    </BtnBlue>
                  </BtnBox>
                </form>
              </CardBody>
            </Content>
          </Card>
        </Content>
      </>
    )
}

export default NewReportCollection