import { useEffect, useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Validation from './validation'
import { handleStoreReport } from 'services/api/reports'
import { handleCards } from 'services/api/reportCards'
import { handleUserShow } from 'services/api/users'
import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import Checkbox from 'components/Form/LabelCheckbox'
import Select from 'components/Form/LabelSelect'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import Table from 'components/Table'
import CardBody from 'components/CardBody'
import BtnBox from 'components/Button/BtnBox'

import { Context } from 'contexts/context'

import { setErrors } from 'utils'

const Page = () => {
  const params = useParams()

  const [cards, setCards] = useState([])
  const [user, setUser] = useState({})

  const { user: { firm_id } } = useContext(Context)

  const history = useHistory()

  const [messages, setMessages] = useState({ messages: [], alert: '' })
  
  const formik = useFormik({
    initialValues: { 
      user_id: params.id, 
      report_id: "",
      roles: '', 
      enable_filters: false,
      page_navigation: false
    },
    validationSchema: Yup.object(Validation(Yup)),
    onSubmit: async () => {
      try {
        await handleStoreReport({
          user_id: formik.values.user_id,
          report_id: formik.values.report_id,
          roles: formik.values.roles,
          enable_filters: formik.values.enable_filters,
          page_navigation: formik.values.page_navigation
        })

        return history.push(`/usuarios/${formik.values.user_id}/relatorios`)
      } catch(error) {
        setMessages({ messages: setErrors(error), alert: 'alert-orange' })
      }
    }
  })

  useEffect(() => {
    (async () => {
      setUser(await handleUserShow(params.id))
    })()
  }, [params.id])

  useEffect(() => {
    (async () => {
      try {
        setCards(await handleCards(firm_id))
      } catch(error) {}
    })()
  }, [firm_id])

  return (
    <>
      <TitleBar label="Usuários" currentPage="" />

      <Content>
        <Card>
          <CardTitle title="Usuário" />

          <Table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Permissão</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.roles}</td>
              </tr>
            </tbody>
          </Table>
        </Card>

        <Card>
          <CardTitle title="Cadastrar"></CardTitle>

          <Content>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Messages formMessages={messages.messages} alert={messages.alert} />
                <Select name="report_id" label="Relatórios" formik={formik}>

                  <option value="">Selecione...</option>

                  {cards.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>

                <Input name="roles" label="Roles" formik={formik} />

                <div style={{ display: 'flex' }}>
                  <Checkbox name='page_navigation' label='Habilitar Abas' formik={formik} checked={false} />
                  <Checkbox name='enable_filters' label='Habilitar Filtros' formik={formik} checked={false} />
                </div>

                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : "Enviar"}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
      </Content>
    </>
  )
}

export default Page