import { createContext, useState } from 'react'

import { PublicClientApplication } from '@azure/msal-browser'

import * as graph from '@microsoft/microsoft-graph-client'

import { msalConfig, loginRequest } from '../config/azure'

export const Context = createContext()

const msalInstance = new PublicClientApplication(msalConfig);

const ContextProvider = ({ children }) => {
    const [toggle, setToggle] = useState(true)

    const [user, setUser] = useState(null)

    const signIn = async () => {
      const response = await msalInstance.loginPopup(
        {
          scopes: loginRequest.scopes,
          prompt: "select_account"
        })

        return response.idToken
    }

    return (
      <Context.Provider value={{ 
        toggle, 
        setToggle, 
        user, 
        setUser,
        signIn
      }}>
        {children}
      </Context.Provider>
    )
}

export default ContextProvider