import { useEffect, useContext, useState } from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'

import { Context } from 'contexts/context'

import LoadingScreen from 'components/LoadingScreen'

import { getUser, userAuthenticated, logout } from 'services/auth'

const Component = ({ component: Component, redirect, ...rest }) => {
    const [error, setError] = useState(false)

    const { user, setUser } = useContext(Context)

    const history = useHistory()

    useEffect(() => {
        (async () => {
          try {
            const data = await getUser()
    
            setUser(data)
          } catch(error) {
            if (error.response?.status === 401) {
              return logout(history)
            }

            setError(true)
          }
        })()
      }, [])

    return (
      <>
        {(user || error) ? (
          <Route {...rest} render={props => userAuthenticated() ? <Component error={error} {...props} /> : <Redirect to={redirect} />}/>  
        ) : <LoadingScreen loading />}
      </>
    )
}

export default Component