import { useEffect, useState } from 'react'
import { FaEdit } from "react-icons/fa"
import { BsFillPlayFill } from "react-icons/bs"
import { HiOutlineDocumentReport } from "react-icons/hi"

import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { Carousel } from 'react-responsive-carousel'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Table from 'components/Table'
import LinkBlue from 'components/Button/LinkBlue'
import SmLinkBlue from 'components/Button/SmLinkBlue'
import BtnOrange from 'components/Button/BtnOrange'
import LoadingScreen from 'components/LoadingScreen'

import Td from 'components/Td'

import api from 'services/api/api'
import { handlePowerBiReportAad } from 'services/api/powerbi'

import { ItemContainer } from './styled'

import "react-responsive-carousel/lib/styles/carousel.min.css"

export default function Carousels() {
    const [carousels, setCarousels] = useState([])
    const [data, setData] = useState([])
    
    const [isLoading, setIsLoading] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [showCarousel, setShowCarousel] = useState(false)
    const [intervalz, setIntervalz] = useState(5000)

    const handle = useFullScreenHandle()

    const handlePlay = async (id) => {
        setIsLoading(true)

        try {
            const { data } = await api.get(`/carousels/${id}/reports/powerbi`)

            setData(data.map(item => ({
                reportId: item.report.report_id,
                embedUrl: item.powerbi.embedUrl,
                token: item.powerbi.accessToken,
                type: models.TokenType.Aad,
                time: item.time
            })))

            setIntervalz(data[0].time)

            handle.enter()
        } catch(error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get(`/carousels/by-firm`)

                setCarousels(data)
            } catch(error) {

            }
          })()
    }, [])

    const getData = async (report_id) => {
        try {
            const reportData = await handlePowerBiReportAad({ report_id })
        
            return reportData.accessToken
        } catch(error) {}
    }

    const onChangeFullScreen = (isFullScreen) => {
        setShowCarousel(isFullScreen)
        
        if  (!isFullScreen) {
            setIsLoaded(false)
        }
    }

    const onChangeCarousel = (_, item) => {
        setIntervalz(item.props["data-interval"])
    }

    return (
        <>
        <TitleBar label="Carrosséis" currentPage="" />

        <Content>
            <Card>
                <CardTitle title="Listagem">
                    <LinkBlue to="/carrosseis/cadastrar">Cadastrar</LinkBlue>
                </CardTitle>

                <Table>
                    <thead>
                        <tr>
                            <th width="60">#</th>
                            <th>Nome</th>
                            <th width="100">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {carousels.map(carousel =>
                            <tr key={carousel.id}>
                                <td>{carousel.id}</td>
                                <td>{carousel.name}</td>

                                <Td>
                                    <BtnOrange style={{ padding: '5px 20px' }} onClick={() => handlePlay(carousel.id)}>
                                        <BsFillPlayFill />
                                    </BtnOrange>

                                    <SmLinkBlue to={`/carrosseis/${carousel.id}/editar`}>
                                        <FaEdit />
                                    </SmLinkBlue>

                                    <SmLinkBlue to={`/carrosseis/${carousel.id}/relatorios`}>
                                        <HiOutlineDocumentReport />
                                    </SmLinkBlue>
                                </Td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card>
        </Content>

        <FullScreen onChange={onChangeFullScreen} handle={handle}>
            {showCarousel && (   
                <Carousel
                    showThumbs={false}
                    stopOnHover={false}
                    showStatus={false}
                    showArrows={false}
                    infiniteLoop={true}
                    showIndicators={false}
                    useKeyboardArrows={false}
                    autoPlay={isLoaded}
                    interval={intervalz}
                    onChange={onChangeCarousel}
                >
                    {data.map((item, index) => (
                        <ItemContainer data-interval={item.time} key={index}>
                            <PowerBIEmbed
                                eventHandlers = {
                                new Map([
                                    ['loaded', () => console.log("Report loaded")],
                                    ['rendered', () => setIsLoaded(true)],
                                    ['error', event => console.log(event.detail)]
                                ])
                                }
                                embedConfig={{
                                    type: 'report',
                                    id: item.reportId,
                                    embedUrl: item.embedUrl,
                                    eventHooks: (item.type === models.TokenType.Aad) && { accessTokenProvider: async () => await getData(item.report.report_id) },
                                    accessToken: item.token,
                                    tokenType: item.type || models.TokenType.Aad,
                                    settings: {
                                        layoutType: models.LayoutType.Custom,
                                        customLayout: {
                                            displayOption: models.DisplayOption.FitToWidth
                                        },
                                        panes: {
                                            filters: {
                                                visible: item.enable_filters ? item.enable_filters : false
                                            },
                                            pageNavigation: {
                                                visible: item.page_navigation ? item.page_navigation : false
                                            }
                                        }
                                    }
                                }}
                                cssClassName={"personalizedReport"}
                            />
                        </ItemContainer>
                    ))}
                </Carousel>
            )} 
        </FullScreen>

        <LoadingScreen loading={isLoading}  />
    </>
    )
}